import "./popover-element.js";

let controllingManagerPopover;

function toggle(event) {
  if (controllingManagerPopover.style.display === "none") {
    controllingManagerPopover.style.display = "block";
  } else {
    controllingManagerPopover.style.display = "none";
  }
  if (event) {
    event.stopPropagation();
  }
}

function setup(frontendCss) {
  controllingManagerPopover = document.createElement("popover-element");
  controllingManagerPopover.setAttribute(
    "description",
    "Single individual with significant responsibility to control, manage or direct the legal entity.",
  );
  controllingManagerPopover.setAttribute("css", frontendCss);
  controllingManagerPopover.style.display = "none";

  // Stop propagation of clicks inside the popover to prevent it from closing
  controllingManagerPopover.addEventListener("click", (event) =>
    event.stopPropagation(),
  );
}

function render() {
  const hasPasswordInstructions = document.getElementsByClassName(
    "password-authenticator--heading",
  )[0];

  if (hasPasswordInstructions) {
    // Description
    const description = document.createElement("p");
    description.innerHTML =
      'To open a LoadPay™ Debit Account, the <span id="controlling-manager" style="border-bottom: 1px dashed #1C7F65; cursor: pointer">controlling manager</span> or sole owner must personally complete the process. This ensures that the account is managed by the authorized individual with the necessary authority and responsibility.';
    description.style.marginBottom = "32px";

    // Add shadow DOM for the popover
    const shadowContainer = document.createElement("div");
    shadowContainer.style.position = "absolute";
    shadowContainer.style.top = "-30px";
    shadowContainer.style.left = "221px";
    const shadow = shadowContainer.attachShadow({ mode: "open" });
    shadow.appendChild(controllingManagerPopover);

    const title = document.getElementsByClassName("okta-form-title")[0];
    title.after(description);
    title.after(shadowContainer);

    document
      .getElementById("controlling-manager")
      .addEventListener("click", toggle);

    // Add a click listener to close the popover when clicking outside
    document.addEventListener("click", function () {
      if (controllingManagerPopover.style.display !== "none") {
        controllingManagerPopover.style.display = "none";
      }
    });
  }
}

export default { setup, render };
